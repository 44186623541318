import store from "@/store";
//import { handleCategoryNavigation } from "./routeFunctions";

let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    props: route => ({
      email: route.query?.email,
      token: route.query?.token
    }),
    meta: { slug: "reset-password" },
    component: () => import("@/views/profile/ResetPassword.vue")
  },
  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    props: { showLogin: true },
    meta: { slug: "reset-password-sent" },
    component: () => import("@/views/EbsnCategory.vue")
  },
  {
    path: "/recover-password-confirm",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password" }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/registration/anonymize-confirmed",
    name: "AnonymizeConfirmed",
    component: () => import("@/views/profile/AnonymizeConfirmed.vue"),
    props: { showLogin: true },
    meta: {
      slug: "anonymize-confirmed"
    }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    props: { showLogin: true },
    meta: { slug: "registration-user" }
  },
  {
    path: "/registration/confirm",
    name: "RegistrationConfirmed",
    component: () => import("@/views/profile/RegistrationConfirmed.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm" }
  },
  {
    path: "/registration/welcome",
    name: "RegistrationCompleted",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      showGoToHome: true
    },
    meta: {
      slug: "registration-welcome"
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true, breadCrumb: "Dashboard" },
    component: () => import("@/views/profile/Dashboard.vue")
  },
  {
    path: "/profile",
    meta: { requiresAuth: true, breadCrumb: "Area personale" },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "account",
        name: "Account",
        meta: {
          requiresAuth: true,
          breadCrumb: "Account",
          showTcoMenu: true,
          slug: "dashboard"
        },
        component: () => import("@/views/profile/Dashboard.vue")
      },
      {
        path: "dashboard",
        name: "ProfileWall",
        meta: {
          requiresAuth: true,
          breadCrumb: "La mia Bacheca",
          slug: "dashboard"
        },
        component: () => import("@/views/profile/ProfileWall.vue")
      },
      {
        path: "profile",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged,
          navToRefName: route.query.navToRefName
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi dati",
          slug: "profile"
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },

      {
        path: "notification",
        name: "NotificationsHistory",
        props: route => ({
          emailchanged: route.query.emailchanged,
          navToRefName: route.query.navToRefName
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue notifiche",
          slug: "notification/notifications-history"
        },
        component: () => import("@/views/notification/NotificationsHistory.vue")
      },

      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie scadenze",
          slug: "duedate"
        },
        component: () => import("@/views/profile/PaymentDueDate.vue")
      },

      {
        path: "news-list",
        name: "NewsListPage",
        props: route => ({
          emailchanged: route.query.emailchanged,
          navToRefName: route.query.navToRefName
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Archivio Messaggi",
          slug: "news-list"
        },
        component: () => import("@/views/profile/NewsList.vue")
      },

      /*
      {
        path: "news-list",
        name: "NewsListPage",
        meta: { requiresAuth: true, breadCrumb: "Archivio Messaggi" },
        component: () => import("@/views/profile/NewsList.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("news-list", to, from, next);
        }
      },
      {
        path: "news-list",
        name: "NewsListPage",
        meta: { requiresAuth: true, breadCrumb: "Archivio Messaggi" },
        component: () => import("@/components/profile/NewsList.vue"),
        beforeEnter(to, from, next) {
          handleCategoryNavigation("news-list", to, from, next);
        }
      },
      */
      {
      path: "/abbonamenti",
      name: "Subscriptions",
      meta: {
        requiresAuth: true,
        slug: "abbonamenti"
      },
    component: () => import("@/views/subscriptions/Subscriptions.vue")
    },
    {
      path: "/i-miei-abbonamenti",
      name: "MySubscriptions",
     
      meta: {
        requiresAuth: true,
        slug: "abbonamenti"
      },
      component: () => import("@/views/subscriptions/Subscriptions.vue")
    },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi dati",
          slug: ["profile/profile-update-error", "profile-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "gifts",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei Coupon",
          slug: "gifts"
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "points",
        name: "ProfilePoints",
        meta: {
          requiresAuth: true,
          breadCrumb: "Saldo Punti e Borsellino",
          slug: ["profile/points", "points"]
        },
        component: () => import("@/views/profile/ProfilePoints.vue")
      },
      {
        path: "changepassword",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile/changepassword", "change-password"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "booked-awards",
        name: "BookerAwards",
        component: () => import("@/views/profile/BookedAwards.vue"),
        meta: { breadCrumb: "Prenotazione premi", slug: ["booked-awards"] }
      },
      {
        path: "awards",
        name: "Awards",
        component: () => import("@/views/profile/Awards.vue"),
        meta: { breadCrumb: "Catalogo Premi", slug: ["awards"] }
      },
      {
        // booking/:listId/:storeId
        path: "booking/:listId",
        name: "Booking",
        props: route => ({
          storeId: route.query.storeId
        }),
        component: () => import("@/views/profile/Booking.vue"),
        meta: { breadCrumb: "Lista prenotazione", slug: ["booking"] }
      },
      {
        path: "booking-completed",
        name: "BookingCompleted",
        props: true,
        component: () => import("@/views/profile/BookingCompleted.vue"),
        meta: {
          breadCrumb: "Prenotazione premi completata",
          slug: ["booking-completed"]
        }
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: { slug: ["profile/profile-confirmed", "profile-confirmed"] }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/profile-updated", "profile-updated"] }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["profile/invite", "invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },

      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I tuoi ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["profile/orders", "orders"]
            },
            component: () => import("@/views/profile/Orders.vue")
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: ["profile/orders/order", "order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "rider",
        meta: {
          requiresAuth: true,
          breadCrumb: "Rider"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Rider",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["profile/rider"]
            },
            component: () => import("@/views/profile/Rider.vue")
          },
          {
            name: "RiderOrderDetail",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              slug: ["rider-order-detail"]
            },
            component: () => import("@/views/profile/RiderOrderDetail.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["lists"]
            },

            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/Lists.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I tuoi indirizzi" },
        // component: () => import("@/views/ProfileAddresses.vue"),
        // beforeEnter(to, from, next) {
        //   handleCategoryNavigation("addresses", to, from, next);
        // },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: ["profile/addresses", "addresses"]
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddresses",
            props: route => ({
              isRider: route.query.isRider
            }),
            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei metodi di pagamento",
          slug: ["profile/payment-method", "payment-method"]
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      }
    ]
  }
];

export default routes;
